import React, {useEffect, useState} from "react";
import {Backdrop, CircularProgress, Container} from "@mui/material";
import SiNoQuestion from "./Questions/SiNoQuestion";
import QuizQuestion from "./Questions/QuizQuestion";
import {firestore} from "../firebase/clientApp";
import axios from "axios";
import {useParams} from "react-router-dom";
import WordcloudQuestion from "./Questions/WordcloudQuestion";
import SentimentQuestion from "./Questions/SentimentQuestion";
import {QnaQuestion} from "./Questions/QnaQuestion";
import {ClickToQuestion} from "./Questions/ClickToQuestion";
import {SendPhotoQuestion} from "./Questions/SendPhotoQuestion";
import {API_URL} from "../config";
import ToolpressQuestion from "./Questions/ToolpressQuestion";
import {QuestionSwitch} from "./Questions/QuestionSwitch";

export default function QuestionContent({elementPlaying, theme}) {
    return(
        <Container sx={{height:'75%', position:'relative', paddingTop:'20px', textAlign:'center'}}>
            <QuestionSwitch elementPlaying={elementPlaying} theme={theme}/>
        </Container>
    )
}
