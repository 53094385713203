import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    Input,
    Stack,
    styled, TextareaAutosize,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import SuccessCheck from "../SuccessCheck";
import {useMotionValue, motion} from "framer-motion";


export const QnaQuestion = ({tipo,sendAnswer, success, answered, sending,elementId}) => {
    const [question, setQuestion] = useState('')
    const [spazio, setSpazio] = useState(false)

    let progress = useMotionValue(90)

    const handleSetQuestion = (event) => {
        setQuestion(event.target.value)
    }

    const handleSendAnswer = () => {
        setSpazio(false)
        sendAnswer(question)
    }

    return(
        <Stack sx={{position:'relative'}} container justifyContent={"start"} alignItems={"start"} style={{textAlign:'center', height:'100%'}} spacing={5}>
            {(!success) && <Box item style={{width: '100%'}}>
                <FormControl variant="standard" style={{width: '100%'}}>
                    <TextField multiline
                        minRows={4}
                        placeholder="Inserisci la tua domanda" className={'custom-textarea'} value={question} onChange={handleSetQuestion}/>
                </FormControl>
                {spazio &&
                    <Typography sx={{mt: 1}}>Puoi inviare solo una parola</Typography>
                }
                <Button disabled={question.length<2} sx={{mt: 4}} variant={'qna'} onClick={handleSendAnswer}>Invia</Button>
            </Box>}
            {(success || answered) &&
                <Box item style={{width:'100%'}}>
                    <motion.div
                        initial={{ x: 0 }}
                        animate={{ x: 100 }}
                        style={{ x: progress }}
                        transition={{ duration: 0.8 }}
                    />
                    <SuccessCheck progress={progress}/>
                </Box>
            }
        </Stack>
    )
}
