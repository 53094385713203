import React, {useEffect, useState} from "react";
import {firestore} from "../firebase/clientApp";
import QuestionBar from "./QuestionBar";
import QuestionContent from "./QuestionContent";
import {Stack} from "@mui/material";

export default function QuestionDialogContent({url, user,idPlaying,open, theme}) {
    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(()=>{
        idPlaying && getElementPlaying()
    }, [idPlaying])

    function getElementPlaying() {
        firestore.collection(url+'/queue').doc(idPlaying).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }
    return(
        <Stack justifyContent={"space-between"} style={{height:'100%', zIndex:1}}>
            <QuestionBar tipo={elementPlaying.tipo} colore={elementPlaying.colore} domanda={elementPlaying.domanda}/>
            <QuestionContent elementPlaying={elementPlaying} user={user} theme={theme}/>
        </Stack>
    )
}
