import React, {useEffect} from "react";
import {Button, Stack} from "@mui/material";

import clap from '../../assets/images/clap.png'
import Fountain from "../Fountain";
import {firebase} from "../../firebase/clientApp";
import {useParams} from "react-router-dom";

export const ClickToQuestion = ({element}) => {
    const {id} = useParams()

    useEffect(() => {
        new Fountain()
    }, [])

    const sendVote = () => {
        firebase.database().ref('users/plesh/events/'+id+'/queue/'+element.id+'/answers').child('clap').transaction(function (searches) {
            return (searches || 0) + 1
        })
    }

  return(
      <Stack sx={{height:'100%'}} justifyContent={"center"}>
          <Button id='clapButton' onClick={() => sendVote('clap')} variant={"clap"} sx={{background:element.colore +'!important'}} startIcon={<img style={{height:'5.625rem'}} src={clap}/>}/>
      </Stack>
  )
}
