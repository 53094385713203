import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    Input, Stack,
    styled,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import ReactWordcloud from "react-wordcloud";
import {firebase} from "../../firebase/clientApp";
import {useParams} from "react-router-dom";
import arrow from '../../assets/arrow.svg'
import wordCheck from "../../functions/wordCheck";
import SuccessCheck from "../SuccessCheck";
import {useMotionValue, motion} from "framer-motion";

export default function WordcloudQuestion({tipo,sendAnswer, success, answered, sending,elementId}){
    const {id} = useParams()
    const [word, setWord] = useState('')
    const [spazio, setSpazio] = useState(false)
    const theme = useTheme()

    let progress = useMotionValue(90)

    const handleSetWord = (event) => {
      setWord(event.target.value)
    }

    function capitalizeTheFirstLetterOfEachWord(words) {
        var separateWord = words.toLowerCase().split(' ');
        for (var i = 0; i < separateWord.length; i++) {
            separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                separateWord[i].substring(1);
        }
        return separateWord.join(' ');
    }

    const handleSendAnswer = () => {
        let w = capitalizeTheFirstLetterOfEachWord(word).trim()

        setSpazio(false)
        /*if(w.includes(' ')){
            setSpazio(true)
        } else {*/
            if(wordCheck(w)){
                sendAnswer(w)
                setWord('')
            } else {
                setWord('')
            }
        /*}*/

    }

    return(
        <Stack sx={{position:'relative'}} justifyContent={"start"} alignItems={"start"} style={{textAlign:'center', height:'100%'}} spacing={5}>
            {(!success) && <Box sx={{width: '100%', mt:5}}>
                <FormControl variant="standard" style={{width: '100%'}}>
                    <TextField
                        inputProps={{style:{fontSize:'1.4rem'}}}
                        placeholder="Insert one word" id="component-simple" value={word} sx={{fontSize:'3rem'}} onChange={handleSetWord}/>
                </FormControl>
                {spazio &&
                    <Typography sx={{mt: 1}}>You can only send one word</Typography>
                }
                <Button disabled={word.length<2} sx={{mt: 4}} variant={'wordcloud'} onClick={handleSendAnswer}>send</Button>
            </Box>}
            {(success || answered) &&
                <Box style={{width:'100%'}}>
                    <motion.div
                        initial={{ x: 0 }}
                        animate={{ x: 100 }}
                        style={{ x: progress }}
                        transition={{ duration: 0.8 }}
                    />
                    <SuccessCheck progress={progress}/>
                </Box>
            }
        </Stack>
    )
}
